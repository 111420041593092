.ContactUsPage {
    flex-wrap: nowrap;
    max-width: 1220px;
    padding: 110px 40px 0px 40px;
    margin: 0 auto;
}

.iframe {
    width: 100%;
    height: 450px;
    border: 0px;
}