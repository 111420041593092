.CategoryBox {
    border-radius: 10px;
    overflow: hidden;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    box-shadow: 3px 3px 70px rgb(0 0 0 / 8%);
    background-color: white;
    width: 29%;
    margin: 20px;

    .title {
        text-align: center;
    }

    img {
        height: 300px;
        object-fit: cover;
        width: 100%;
    }
}

.CategoryBox.Reference {
    width: 19%;
    margin: 0.5%;

    img {
        object-fit: contain;
    }
}