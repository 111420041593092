.Header {
    position: fixed;
    width: 100%;
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 20%);
    z-index: 2;

    .header-details {
        top: 0px;
        height: 40px;
        width: calc(100% - 40px);
        // background-color: #e9e9e9;
        background: #0b151c;
        color: #fff;
        padding-right: 20px;
        padding-left: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 500;

        .mail_top {
            text-decoration: none;
            color: #fff;
        }
    }

    .contact-details {
        font-weight: 600;
        font-size: 1.1rem;

        .phone_top {
            a {
                text-decoration: none;
                color: #fff;
            }
        }
    }

    .header-top {
        top: 40;
        width: 100%;
        z-index: 1;
        background: #1a2830;
        color: #fff;
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .header-logo {
            margin-left: 20px;
        }

        .header-bar {
            margin-right: 20px;
            
            .bar-item {
                padding: 8px 16px;
                float: left;
                width: auto;
                border: none;
                display: block;
                outline: 0;
                white-space: normal;
                border: none;
                display: inline-block;
                padding: 8px 16px;
                overflow: hidden;
                text-decoration: none;
                color: inherit;
                background-color: inherit;
                text-align: center;
                cursor: pointer;
                white-space: nowrap;
                font-size: 1.45rem;
                font-weight: 700;
                padding: 0rem 1rem 0rem 1rem;
                font-family: "Akrobat","tajawal";
            }
        }
        
    }

    .bar-white {
        color: #000!important;
        background-color: #fff!important;
        position: relative;
        animation: animatetop 0.4s;
    }
}