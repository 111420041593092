@import url('https://fonts.cdnfonts.com/css/akrobat');

body {
  margin: 0;
  font-family: "Akrobat", "tajawal";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.page-title {
  text-align: center;
  font-size: 3rem;
}

.page-sub-title {
  text-align: center;
}