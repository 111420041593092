.Footer {
    background: #0b151c;
    color: #fff;
    text-align: center;
    font-size: 20px;

    .footer-head {
        display: flex;
        justify-content: space-evenly;
        padding: 20px;
    }

    .footer-buttom {
        background: #03080b;
        padding: 20px;
    }

    .footer-div {
        width: 25%;
    }

    .footer-list {
        a {
            text-decoration: none;
            color: #fff;
        }
    }
}