.HomePage {
    background-color: #12141c;
}

.homeVideoPlayer {
    width: 100%;

    video {
        width: 100%;
    }
}

