.resised-col-50 {
  width: 50%;
  display: inline-block;
}

.resised-col-100 {
  width: 100%;
  display: block;
} 

.list-unstyled {
  padding-left: 0;
  list-style: none;
}