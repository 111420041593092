.Row {
    width: calc(100% - 40px);
    padding: 50px 20px;

    .RowTitle {
        color: #fff;
        text-align: center;
        font-size: 3.5rem;
        margin-bottom: -15px;
    }

    .RowSubTitle {
        color: #fff;
        text-align: center;
        font-size: 1.5rem;
        max-width: 1000px;
        margin: 0 auto;
        margin-top: 30px;
    }
}

.BoxRow {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: calc(100% - 40px);
}

.ReferencesBoxes {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: calc(100% - 40px);
}