.ActivityLayout {
    display: flex;
    flex-wrap: nowrap;
    max-width: 1220px;
    padding: 110px 40px 0px 40px;
    margin: 0 auto;
    font-family: "Akrobat", "tajawal";

    
    .left-side {
        width: 70%;
        padding: 30px;
    }

    // .rightside {
    //     width: 30%;
    //     background: #1a2830;
    //     padding: 20px;
    //     color: #fff;
    // }

    .top_page .titre_main {
        margin-bottom: 19px;
    }

    .titre_main {
        font: 25px/35px msb;
        color: #1a2830;
        text-transform: uppercase;
        margin-bottom: 6px;
        font-weight: 600;
        font-family: "Akrobat", "tajawal";
    }

    .chapo {
        font: 18px/32px sspr;
    }

    .rightside {
        width: 27%;

        .activity-list {
            .activity-title {
                font-size: 17px;
                font-weight: 600;
            }

            ul {
                li {
                    border-left: 2px solid #E8EAED;
                    color: #5F6368;
                    display: block;
                    font-family: "Google Sans", "Helvetica", sans-serif;
                    font-size: 16px;
                    font-weight: 500;
                    padding: 8px 0 8px 16px;
                }

                li.active {
                    border-left: 2px solid #E8EAED;
                }
            }
        }
    }
}